import { Grid, Typography } from "@mui/material";
import "../../index.css";

const AttesaContenuti = ({ nomeSala }) => {

    return (
        <>
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} md={12} className="centerVisualization">
                    <Typography variant="h2" className="boldBlueText textUppercase" sx={{ fontSize: '5rem' }}>{nomeSala}</Typography>
                </Grid>
                <Grid item xs={12} md={12} className="centerVisualization boldBlueText">
                    <Typography variant="h5" className="boldBlueText">In attesa dei contenuti</Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default AttesaContenuti;