import { AppBar, MenuItem, Toolbar } from "@mui/material";
import logo from '../../assets/LogoInail_small_bianco.png';
import "../../index.css";

const Navbar = () => {

    return (
        <>
            <AppBar className="appBarStyle">
                <Toolbar className="centerVisualization blueBackground">
                    <MenuItem key="Titolo">
                        <img src={logo} alt="" className="appBarLogoSize"/>
                    </MenuItem>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
