import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOpen: false, isEdit: true, dialogType: "", dialogPageType: "", message: "", qrCodeRecupero: "", tempoAttesaRipristinoSessione: "", tempoSos: "" };

const salaSlice = createSlice({
   name: 'dialogSala',
   initialState,
   reducers: {
      openDialogSala: (state, payload) => {
         return {
            ...state, isOpen: true,
            dialogType: payload.payload.dialogType,
            isEdit: payload.payload.isEdit,
            message: payload.payload.message,
            qrCodeRecupero: payload.payload.qrCodeRecupero,
            tempoAttesaRipristinoSessione: payload.payload.tempoAttesaRipristinoSessione
         }
      },
      closeDialogSala: (state) => {
         return { ...state, isOpen: false, tempoSos: "" };
      },
      setTempoSos: (state, payload) => {
         return { ...state, tempoSos: payload.payload };
      },
      clearTempoSos: (state, payload) => {
         return { ...state, tempoSos: "" };
      }
   }
});

export const { openDialogSala, closeDialogSala, setTempoSos, clearTempoSos } = salaSlice.actions;

const { reducer } = salaSlice;

export default reducer;