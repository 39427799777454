import { Box } from "@mui/material";
import Navbar from "./navbar/Navbar";
import "../index.css";

const Layout = ({ children }) => {

    return (
        <>
            <Box>
                <Navbar />
                <Box component="main" className="backgroundImage">
                    <Box mt={10}sx={{paddingX: '3rem', width: '100%'}}> {children} </Box>
                </Box>
            </Box>
        </>
    );
};

export default Layout;
