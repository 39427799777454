import { useRef, useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { ListenerSala, ComandiMultimedia } from "../../consts/dictionary";
import Loader from '../../components/Loader';
import ConnectionContext from "../../context/ConnectionContext";
import "../../index.css";

const Guida = () => {

    const elementReference = useRef(null);
    const myButton = useRef(null);
    const connection = useContext(ConnectionContext);
    const [activeSlide, setActiveSlide] = useState();
    const [slideExtension, setSlideExtension] = useState();
    const [listenerConfigured, setListenerConfigured] = useState(false);
    const [loadingState, setLoadingState] = useState(true);
    const [mutedVideo, setMutedVideo] = useState(true);

    document.elementFromPoint(100,100).click();

    useEffect(() => {
        let connectionState;
        if (connection) connectionState = connection._connectionState;
        if (connectionState === 'Connected' && !listenerConfigured) {
            defineListenerNewSlide();
            defineLoadingSlideState();
            definePlayPauseHandler();
            setListenerConfigured(true);
        }
    }, []);

    const defineListenerNewSlide = async () => {
        await connection.on(ListenerSala.ReceiveSlideGuida, (slide) => {
            setActiveSlide(slide);
            setSlideExtension(slide.estensione.slice(1));           
            setLoadingState(false);
        });
    }

    const defineLoadingSlideState = async () => {
        await connection.on(ListenerSala.LoadingSlide, (loading) => {
            setLoadingState(loading);
        });
    }

    const definePlayPauseHandler = async () => {
        await connection.on(ListenerSala.GetMultimediaCommand, (command) => {
            executeMultimediaCommand(command);
        });
    }


    const executeMultimediaCommand = (command) => {
        let mediaReference = elementReference.current;

        if (mediaReference) mediaReference.focus();

        if (mediaReference && command === ComandiMultimedia.Play) {
             mediaReference.play();
             setMutedVideo(true);
        }
        if (mediaReference && command === ComandiMultimedia.Pause) mediaReference.pause();

    }

    if (loadingState) {
        return (
            <Loader />
        )
    } else {
        return (
            <>
                <Grid container className={activeSlide?.tipoFile !== 2 ? "slideContainer" : "audioSlideContainer"}>
                    {activeSlide?.tipoFile === 1 && <img src={'data:image/'+ slideExtension + ';base64,' + activeSlide?.file} alt="" className="imageFullSize" />}
                    {activeSlide?.tipoFile === 2 && <audio ref={elementReference}  id="audioSlide" controls src={'data:audio/'+ slideExtension + ';base64,' + activeSlide?.file} alt="" className="" />}
                    {activeSlide?.tipoFile === 3 && <video ref={elementReference} id="videoSlide" controls src={'data:video/'+ slideExtension + ';base64,' + activeSlide?.file} alt="" className="imageFullSize" />}
                </Grid>
                <button id="hidden-element" style={{ display: 'none' }}></button>
            </>
        )
    }
}

export default Guida;