import React from "react";
import { useSelector } from "react-redux";
import { clearTempoSos, closeDialogSala } from "../../../reducers/dialogSala-reducer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";
import { HubEndpoint, Messaggi, NotificationStatus } from "../../../consts/dictionary";
import { notificationOpen } from "../../../reducers/notification-reducer";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const SalaDialog = ({ connection }) => {

    const dialogState = useSelector((state) => state.dialogSala);
    
    const backButtonState = useSelector((state) => state.dialogBackButton);
    const dispatch = useDispatch();
    const { rowInfo, dialogType, dialogPageType, message, tempoAttesaRipristinoSessione, tempoSos } = dialogState;


    //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
    useEffect(() => {
        dispatch(closeDialogSala());
    }, [window.onhashchange]);

    useEffect(() => {
        dispatch(closeDialogSala());
    }, [backButtonState]);


    return (
        <>
            {dialogState.dialogType === "helpDeskMessage" && (
                <>
                    <Dialog
                        open={dialogState.isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth={"lg"}
                    >
                        <DialogTitle sx={{ color: 'white', fontSize: '2rem', backgroundColor: '#002E5D', border: '1px solid white', borderRadius: '4px' }}>Messaggio di sistema</DialogTitle>
                        <DialogContent className="border-Content-dialog" sx={{ marginY: '9rem' }}>
                            <DialogContentText id="alert-dialog-description"
                                sx={{
                                    fontSize: '3.5rem',
                                    color: '#212121',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflow: 'hidden!important',
                                }}>
                                {dialogState.message}
                                {dialogState.tempoSos && (
                                    <div>
                                        <CountdownCircleTimer
                                            isPlaying
                                            size={90}
                                            strokeWidth={15}
                                            strokeLinecap="square"
                                            rotation="counterclockwise"
                                            duration={parseInt(dialogState.tempoSos)}
                                            colors={['#002E5D']}
                                            onComplete={async () => {
                                                console.log("timer scaduto");
                                                dispatch(closeDialogSala());
                                                await connection.invoke(HubEndpoint.ResettaSegnaleSOS);
                                            }}
                                            sx={{ textAlign: 'center', marginTop: '1rem' }}
                                        >
                                            {({ remainingTime }) => <span className="boldBlueText textUppercase" style={{ fontSize: '1.5rem' }}>{remainingTime}</span>}
                                        </CountdownCircleTimer>
                                    </div>
                                )}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </>
            )}
            {dialogState.dialogType === "VisualizzaQrCodeRecupero" && (
                <>
                    <Dialog
                        open={dialogState.isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth={"lg"}
                        sx={{
                            overflow: 'hidden!important'
                        }}
                    >
                        <DialogTitle sx={{ color: 'white', fontSize: '2rem', backgroundColor: '#002E5D', border: '1px solid white', borderRadius: '4px' }}>Recupero connessione</DialogTitle>
                        <DialogContent className="border-Content-dialog" sx={{
                            flexDirection: 'column',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden!important'
                        }}>
                            <DialogContentText id="alert-dialog-description" sx={{
                                fontSize: '3.5vh',
                                flexDirection: 'column',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                color: '#002E5D'
                            }}
                                mt={2}
                                mb={2}
                            >
                                <img src={'data:image/jpeg;base64,' + dialogState.qrCodeRecupero} style={{ width: '30vh' }} alt=""></img>
                                {dialogState.message}
                                <CountdownCircleTimer
                                    isPlaying
                                    size={90}
                                    strokeWidth={15}
                                    strokeLinecap="square"
                                    rotation="counterclockwise"
                                    duration={parseInt(tempoAttesaRipristinoSessione)}
                                    colors={['#002E5D']}
                                    onComplete={() => {
                                        dispatch(closeDialogSala());
                                        try {
                                            connection.invoke(HubEndpoint.TimerRecuperoSessioneScaduto);
                                        } catch (error) {
                                            console.log('Errore durante l\'invocazione SignalR:', error);
                                            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                                        }
                                    }}
                                    sx={{ textAlign: 'center', marginBottom: '1rem' }}
                                >
                                    {({ remainingTime }) => <span className="boldBlueText textUppercase" style={{ fontSize: '1.5rem' }}>{remainingTime}</span>}
                                </CountdownCircleTimer>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </>
            )
            }
        </>
    );
};

export default SalaDialog;
