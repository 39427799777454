import { Typography, Grid } from "@mui/material";
import "../../index.css";

const ErrorPage = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} mt={10} className="centerVisualization">
          <Typography variant="h6" className="boldBlueText" sx={{fontSize: '2rem'}}>Pagina non trovata.</Typography> 
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorPage;
