import { Grid, Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import "../index.css";


export default function Loader() {

  return (
    <>
        <Grid container className="centerVisualization">
            <Grid className="flex-center-center" item xs={12} md={12} lg={12} >
                <Backdrop open={true} className="backdrop">
                    <CircularProgress size={100} thickness={5} className="loader"/>
                </Backdrop>          
            </Grid>
        </Grid>
    </>
  )
}