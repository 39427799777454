import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { notificationClose, notificationOpen } from "./reducers/notification-reducer";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ListenerSala, HubEndpoint, InternalAppRoutes, Messaggi, NotificationStatus } from "./consts/dictionary";
import Homepage from "./pages/homepage/Homepage";
import Countdown from "./pages/countdown/Countdown";
import Guida from "./pages/guida/Guida";
import AttesaContenuti from "./pages/attesa_contenuti/AttesaContenuti";
import Teletabber from "./pages/teletabber/Teletabber";
import Layout from "./layout/Layout";
import ErrorPage from "./pages/error_page/ErrorPage";
import Notification from "./components/Notification";
import ConnectionContext from "./context/ConnectionContext";
import './App.css';
import { closeDialogSala, openDialogSala, setTempoSos, clearTempoSos } from "./reducers/dialogSala-reducer";
import SalaDialog from "./components/dialogs/sala_dialog/SalaDialog";
import axios from "axios";

function App() {

  const [hubUrl, setHubUrl] = useState();
  const [sosTimeout, setSosTimeout] = useState();
  const [sosInviatoTimeout, setSosInviatoTimeout] = useState();

  const fetchSettings = async () => {
    try {
      const response = await axios.get("/appSettings.json");
      const data = response.data;
      const sosTimeout = data.SOS_COMMAND_TIMEOUT;
      setSosTimeout(sosTimeout);
      setSosInviatoTimeout(data.SOS_INVIATO_TIMER);
      setHubUrl(data.REACT_APP_HUB_ADDRESS);

    } catch (error) {
      console.error("Errore durante la richiesta:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.notification);
  const [connection, setConnection] = useState();
  const [route, setRoute] = useState();
  const [salaInfo, setSalaInfo] = useState();
  const [idSala, setIdSala] = useState();
  const [countdown, setCountdown] = useState();
  const [nomeSala, setNomeSala] = useState();
  const [teletabberUrl, setTeletabberUrl] = useState();

  useEffect(() => { if (!connection && hubUrl) startConnection() }, [hubUrl]);

  const startConnection = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(hubUrl)
        .configureLogging(LogLevel.Information)
        .build();

      connection.serverTimeoutInMilliseconds = 30000000;


      connection.on(ListenerSala.CambiaPagina, (route, hubPayload) => {

        switch (route) {
          case 1:
          case 3:
          case 5:
          case 6:
            break;

          case 2:
            setTeletabberUrl(hubPayload);
            break;

          case 4:
            if (hubPayload.countdown && hubPayload.nomeSala) {
              setCountdown(hubPayload.countdown);
              setNomeSala(hubPayload.nomeSala);
            } else {
              setCountdown((prevState) => prevState);
              setNomeSala((prevState) => prevState);
            }
            break;

          default:
            console.error('Route ' + route + 'non gestita');
        }
        navigate('/' + InternalAppRoutes[route]);
      });

      connection.on(ListenerSala.ReceiveNewQrCode, (salaInfo) => {
        setSalaInfo(salaInfo);
      });

      connection.on(ListenerSala.SendError, (message) => {
        dispatch(notificationOpen({ message: message, status: NotificationStatus.Warning }));
      });

      connection.on(ListenerSala.VisualizzaRispostaSos, (message) => {
        dispatch(openDialogSala({ dialogType: "helpDeskMessage", isEdit: false, message: message }));
      });

      connection.on(ListenerSala.ResettaSegnaleSOS, () =>{
        dispatch(notificationClose())
      });

      connection.on(ListenerSala.MostraConfermaInvioSos, () => {
        dispatch(setTempoSos(sosTimeout / 1000))
        dispatch(openDialogSala({ dialogType: "helpDeskMessage", isEdit: false, message: 'Per procedere con la richiesta di SOS, premere nuovamente il Magic Button.' }));

      });

      connection.on(ListenerSala.NascondiConfermaInvioSos, async () => {
        dispatch(clearTempoSos());
        dispatch(openDialogSala({ dialogType: "helpDeskMessage", isEdit: false, message: 'SOS inviato. Attendere supporto.' }));
        setTimeout(() => {
          dispatch(setTempoSos(sosInviatoTimeout)); 
        },10)
      });

      connection.on(ListenerSala.ChiudiSos, (tempoSOS) => { dispatch(setTempoSos(parseInt(tempoSOS))); });

      connection.on(ListenerSala.VisualizzaQrCodeRecupero, (qrCodeRecupero, tempoAttesaRipristinoSessione) => {
        dispatch(openDialogSala({
          dialogType: "VisualizzaQrCodeRecupero",
          isEdit: false,
          message: 'Inquadrare il Qr Code per procedere con la riconnessione.',
          qrCodeRecupero: qrCodeRecupero,
          tempoAttesaRipristinoSessione: tempoAttesaRipristinoSessione
        }));
      });

      connection.on(ListenerSala.NascondiQrCodeRecupero, () => {
        dispatch(closeDialogSala());
      });

      connection.onreconnecting(error => {
        console.log(`Connessione persa a causa dell'errore:"${error}". Tentativo di riconnessione.`)
      });

      connection.onreconnected(connectionId => {
        console.log(`Connessione ristabilita. Connesso con connectionId "${connectionId}".`)
      });

      await connection.start();
      setConnection(connection);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    setIdSala(params.get('idSala'));

    if (idSala) associaSala();
  }, [connection]);

  const associaSala = async () => {
    try {
      await connection.invoke(HubEndpoint.AssociaSala, parseInt(idSala));
      navigate('/' + InternalAppRoutes[5]);
    } catch (error) {
      console.log('Errore durante l\'invocazione SignalR:', error);
      dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
    }
  }

  return (
    <>
      <ConnectionContext.Provider value={connection}>
        <Routes>
          <Route path="/" element={<><Layout></Layout></>} />
          <Route path="/homepage" element={<Homepage salaInfo={salaInfo} route={route} />} />
          <Route path="/attesacontenuti" element={<Layout> <AttesaContenuti nomeSala={nomeSala} /> </Layout>} />
          <Route path="/countdown" element={<Layout> <Countdown countdown={countdown} nomeSala={nomeSala} idSala={idSala} /> </Layout>} />
          <Route path="/guida" element={<Guida />} />
          <Route path="/teletabber" element={<Teletabber teletabberUrl={teletabberUrl} />} />
          <Route path="*" element={<Layout> <ErrorPage /> </Layout>} />
        </Routes>
        <Notification></Notification>
      </ConnectionContext.Provider>
      <SalaDialog connection={connection}></SalaDialog>
    </>
  );
}

export default App;
