import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { HubEndpoint, AzioneSala, Messaggi, NotificationStatus } from "../../consts/dictionary";
import { notificationOpen } from "../../reducers/notification-reducer";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Notification from "../../components/Notification";
import ConnectionContext from "../../context/ConnectionContext";
import "../../index.css";

const Countdown = ({ countdown, idSala, nomeSala }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const connection = useContext(ConnectionContext);
    const state = useSelector((state) => state.notification);

    const terminaSessione = async () => {
        try {
            let connectionState;
            if (connection) connectionState = connection._connectionState;

            if (connectionState === 'Connected') {
                await connection.invoke(HubEndpoint.SendMessageFromSala, AzioneSala.COUNTDOWN_TERMINATO).then((response) => {
                    if (response.ok) {
                        navigate('/homepage?idSala=' + idSala);
                    } else {
                        dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
                    }
                });
            } else {
                dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                navigate('/homepage?idSala=' + idSala);
            }
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            navigate('/homepage?idSala=' + idSala);
        }
    }
    
    return (
        <>
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} md={12} className="centerVisualization">
                    <Typography variant="h2" className="boldBlueText textUppercase" sx={{ fontSize: '5rem' }}>{nomeSala}</Typography>
                </Grid>
                <Grid item xs={12} md={12} className="centerVisualization">
                    <Typography variant="h5" className="boldBlueText">In attesa dei contenuti</Typography>
                </Grid>
                <Grid item xs={12} md={12} mt={6} className="centerVisualization">
                    <CountdownCircleTimer
                        isPlaying
                        size={250}
                        strokeWidth={20}
                        strokeLinecap="square"
                        rotation="counterclockwise"
                        duration={parseInt(countdown)}
                        colors={['#5BA7E0' ]}
                        trailColor="#787878"
                        onComplete={() => terminaSessione()}
                        sx={{ textAlign: 'center' }}
                    >
                        {({ remainingTime }) => <Typography className="boldBlueText textUppercase" sx={{ fontSize: '5rem' }}>{remainingTime}</Typography>}
                    </CountdownCircleTimer>
                </Grid>
            </Grid>
            <Notification></Notification>
        </>
    )
}

export default Countdown;