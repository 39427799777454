import { Grid } from "@mui/material";
import "../../index.css";

const Teletabber = ({ teletabberUrl }) => {

    return (
        <>
            <Grid container className="iframeContainer">
                <iframe id="idTeletabber" title="teletabberFrame" src={teletabberUrl} className="iframeContent"></iframe>
            </Grid>
        </>
    )
}

export default Teletabber;