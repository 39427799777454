import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from '../reducers/notification-reducer';
import dialogSalaReducer from "../reducers/dialogSala-reducer";

const store = configureStore({
    reducer: {
        notification: notificationReducer,
        dialogSala: dialogSalaReducer
    }
});

export default store; 