import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { HubEndpoint, Messaggi, NotificationStatus } from '../../consts/dictionary';
import { notificationOpen } from "../../reducers/notification-reducer";
import fasciaInail from '../../assets/Fascia_Inail.png';
import Layout from "../../layout/Layout";
import Notification from "../../components/Notification";
import ConnectionContext from "../../context/ConnectionContext";
import "../../index.css";
import axios from "axios";

// function useInterval(callback, delay) {
//     const savedCallback = useRef();

//     useEffect(() => {
//         savedCallback.current = callback;
//     }, [callback]);

//     useEffect(() => {
//         function tick() {
//             savedCallback.current();
//         }

//         if (delay !== null) {
//             const intervalId = setInterval(tick, delay);
//             return () => clearInterval(intervalId);
//         }
//     }, [delay]);
// }


const Homepage = ({ route, salaInfo }) => {

    const [timeIntervalQrCode, setTimeIntervalQrCode] = useState();
    const [timeIntervalLogoInail, setTimeIntervalLogoInail] = useState();

    const fetchSettings = async () => {
        const response = await axios.get("/appSettings.json");
        const data = response.data;
        setTimeIntervalQrCode(data.QR_CODE_TIME_SWITCH);
        setTimeIntervalLogoInail(data.LOGO_INAIL_TIME_SWITCH);
    }


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const connection = useContext(ConnectionContext);
    const state = useSelector((state) => state.notification);
    const [showQRCodes, setShowQRCOdes] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    const showQRCodesRef = useRef(showQRCodes);

    useEffect(() => {
        showQRCodesRef.current = showQRCodes;
        // setTimeIntervalQrCode(timeIntervalQrCode);
        // setTimeIntervalLogoInail(timeIntervalLogoInail);
        let connectionState;
        if (connection) connectionState = connection._connectionState;

        const intervalId = setInterval(() => {
            if (connectionState === 'Connected') {
                setShowQRCOdes((statoPrecedente) => !statoPrecedente);
            } else {
                setShowQRCOdes(false);
            }
        }, showQRCodes ? timeIntervalQrCode : timeIntervalLogoInail);
        return () => clearInterval(intervalId);
    }, [showQRCodes]);


    useEffect(() => {
        let connectionState;
        if (connection) connectionState = connection._connectionState;
        if (connectionState === 'Connected') {
            setIsConnected(true);
            try {
                fetchSettings();
                connection.invoke(HubEndpoint.SendQrCode);
            } catch (error) {
                console.log('Errore durante l\'invocazione SignalR:', error);
                dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                setIsConnected(false);
            }
        } else {
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
        }
    }, []);




    useEffect(() => { if (route) navigate('/' + route); }, [route])

    return (
        <>
            {(!showQRCodes || !salaInfo) &&
                <Box className="centerVisualization homePageBackground">
                    <img src={fasciaInail} alt="" className="imageFullWidthOnly" />
                </Box>
            }
            {showQRCodes && salaInfo &&
                <Layout>
                    <Grid container spacing={2} columns={16} mt={4} className="spaceAroundVisualization" >
                        <Grid item xs={6} sm={6} md={6} lg={6} className="blueQRCodeContainer" sx={{ paddingLeft: '0px!important' }}>
                            <Grid container mt={0} className="spaceAroundVisualization" style={{flexDirection:'column'}}>
                                <Typography className="boldWhiteText">Se sei utente INAIL <br></br></Typography>
                                {salaInfo && <img style={{marginTop:'10px'}} src={'data:image/jpeg;base64,' + salaInfo.qrCodeInail} alt=""></img>}
                                <div style={{display:'flex'}}>
                                    <Typography mt={2} className="whiteText">Inquadra questo QR Code
                                        <br></br>oppure
                                        <br></br>se hai un notebook apri l'app
                                        <br></br><b>ROOMS MEETING </b>
                                        <br></br>e digita:&nbsp;
                                        <p className="boldWhiteText">{salaInfo.pinAccessoSala}</p>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} className="greyQRCodeContainer">
                            <Grid container mt={0} className="spaceAroundVisualization">
                                <Typography className="boldWhiteText">Se non sei utente INAIL <br></br></Typography>
                                {salaInfo && <img style={{marginTop:'10px'}} src={'data:image/jpeg;base64,' + salaInfo.qrCodeEsterni} alt=""></img>}
                                <div>
                                    <Typography mt={2} className="whiteText">Inquadra questo QR Code
                                        <br></br>oppure
                                        <br></br>accedi all'app tramite l'indirizzo
                                        &nbsp;
                                        <b>{salaInfo.urlEsterni}</b>
                                        <br></br>e digita:&nbsp;
                                        <p className="boldWhiteText">{salaInfo.pinAccessoSala}</p>
                                    </Typography>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                </Layout>
            }
            <Notification></Notification>
        </>
    )
}

export default Homepage;