//ROUTES
export const InternalAppRoutes = {
   1: 'attesacontenuti',
   2: 'teletabber',
   3: 'guida',
   4: 'countdown',
   5: 'homepage',
   6: 'qrCodeRecuperoSessione'
}

//AZIONI SALA
export const AzioneSala = {
   COUNTDOWN_TERMINATO: 1
}

//HUB ENDPOINT
export const HubEndpoint = {
   AssociaSala : 'AssociaSala',
   SendQrCode: 'SendQrCode',
   SendMessageFromSala: 'SendMessageFromSala',
   TimerRecuperoSessioneScaduto: 'TimerRecuperoSessioneScaduto',
   ResettaSegnaleSOS: 'ResettaSegnaleSos'
}

//CLIENT LISTENER NAMES
export const ListenerSala = {
   CambiaPagina : 'CambiaPagina',
   SendError: 'SendError',
   ReceiveNewQrCode: 'ReceiveNewQrCode',
   ReceiveSlideGuida: 'ReceiveSlideGuida',
   GetMultimediaCommand: 'PlayPause',
   LoadingSlide: 'LoadingSlide',
   VisualizzaRispostaSos: 'VisualizzaRispostaSos',
   ChiudiSos: 'ChiudiSos',
   MostraConfermaInvioSos: 'MostraConfermaInvioSos',
   NascondiConfermaInvioSos: 'NascondiConfermaInvioSos',
   VisualizzaQrCodeRecupero: 'VisualizzaQrCodeRecupero',
   NascondiQrCodeRecupero: 'NascondiQrCodeRecupero',
   ResettaSegnaleSOS: "ResettaSegnaleSOS"
}

//MESSAGGI INFO/ERROR
export const Messaggi = {
   ComunicazioneServerAssente: 'Errore di comunicazione con il server.'
}

//NOTIFICATION STATUS
export const NotificationStatus = {
   Error: 'error',
   Warning: 'warning',
   Info: 'info',
   Success: 'success'
}

//COMANDI GESTIONE CONTENUTI MULTIMEDIALI GUIDA
export const ComandiMultimedia = {
   Play: 1,
   Pause: 2
}